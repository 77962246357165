body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-bar {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #f2f2f2;
  overflow: hidden;
}

.loading-bar-fill {
  height: 100%;
  background-color: #007bff;
  animation: loading-bar-fill-animation 4s infinite;
}

@keyframes loading-bar-fill-animation {
  0% {
    width: 0%;
    background-color: transparent;
  }

  25% {
    width: 50%;
    background-color: #007bff;
  }

  50% {
    width: 50%;
    background-color: transparent;
  }

  75% {
    width: 100%;
    background-color: #007bff;
  }

  100% {
    width: 100%;
    background-color: transparent;
  }
}

.New_password_modal .ant-modal-content {
  width: 450px;
  border-radius: 24px;
}

.delete_modal .ant-modal-content {
  width: 475px;
  border-radius: 24px;
}

.feedback_modal .ant-modal-content {
  width: 475px;
  border-radius: 24px;
}

.videocalll_modal .ant-modal-content{
  width: 475px;
  border-radius: 24px;
}

.logout_modal .ant-modal-content {
  width: 475px;
  border-radius: 24px;
}

.request_modal .ant-modal-content {
  width: 475px;
  border-radius: 24px;
}

.select_coach_modal .ant-modal-content {
 width: 475px;
height: 427px;
  border-radius: 24px;
}


.add_employee_modal .ant-modal-content {
  width: 420px;
  border-radius: 24px;
}
.Add_slot_modal .ant-modal-content {
  width: 422px;
  border-radius: 24px;
}
.Session_Modal .ant-modal-content {
  width: 470px;
  height: 575px;
  padding: 0;
  border-radius: 24px;
}

video {
  width: 100% !important;
  height: 185px !important;
}

.select_dropdown .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #43434340 !important ;
  padding: 0 !important;
  border-radius: 0;
  font-size: 1rem;
}
.select_dropdown .ant-select-selection-placeholder {
  color: #2b2b2b !important;
}
.select_dropdown .ant-select-selector:focus {
  outline: none !important;
}
.ant-tabs-ink-bar {
  padding: 2px;
  background-color: #e4bf44 !important; 

}

.ant-tabs-tab-btn {
  color: #2b2b2b !important;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  padding-bottom: 0.5rem;
}

.ant-tabs-nav-list {
  padding: 0 2.2rem;
}

.MuiFormLabel-root.Mui-focused {
  color: #93938d !important;
}
.MuiInput-underline::after {
  border-bottom: none !important;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}
.default-star {
  color: #000;
  background-color: green;
  /* Default color for non-selected stars */
}

.red-star {
  color: #000;
  background-color: red;
  /* Red color for selected star */
}

.MuiInputBase-input.Mui-disabled {
  color: #2b2b2b;
}

.track video{
  object-fit: cover !important;
}

.track.mirror {
  transform: scaleX(-1);
}
