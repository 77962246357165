.modal_time_picker {
    width: 135px !important;
    height: 48px !important;
  
    
  ::placeholder{
    color: #2b2b2b !important;
  }
   
  }
  .ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #e4bf44 !important;
  }
  
  .ant-btn-primary{
    background-color: #e4bf44 !important;
    color: #2b2b2b;
  }
  a{
  color: #e4bf44 !important;
  }
  
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active{
    background-color: #e4bf44 !important;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell:focus {
    background-color: #e4bf44 !important;
  }
  .ant-picker-time-panel-cell:active{
    background-color: #e4bf44 !important;
  }
  .ant-picker:hover, :where(.css-dev-only-do-not-override-yp8pcc).ant-picker-focused {
    border-color: #e4bf44 !important;
    box-shadow: none !important;
  
  }
  .ant-btn.ant-btn-sm {
    font-size: 13px ;
    height: 24px;
    padding: 0px 14px;
    border-radius: 4px;
    color: #2b2b2b;
  }
  .ant-picker-dropdown .ant-picker-ranges {
    margin-top: 0;
  }
  .ant-picker-dropdown .ant-picker-time-panel-column {
    
     width: auto !important;
  }