@import "~react-big-calendar/lib/sass/styles";

.my-calendar-setting {
  .rbc-header {
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 124.5%;
    text-align: center;
    color: #45485b;

    .rbc-header + .rbc-header {
      border-left: none !important;
    }

    .rbc-row-bg {
      display: none !important;
    }
  }
  .rbc-date-cell {
    text-align: center;
  }
  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-today {
    background-color: transparent;
    .rbc-header-date {
      background: #e4bf44 !important;
    }
  }

  .rbc-current {
    .rbc-button-link {
      background-color: #e4bf44;
      padding: 8px;
      border-radius: 50%;
      position: relative !important;
      z-index: 1 !important;
    }
  }

  .rbc-row-segment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    // background-color: #e4bf44;
    background-color: #39ff14;
    max-width: 190px;
    font-size: 10px;
    color: #2b2b2b;
    font-weight: 600;
    padding: 0;
  }

  .rbc-day-slot .rbc-event-label {
    display: none;
  }
  .rbc-timeslot-group {
    width: 100%;
    min-height: 50px;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-time-header-content {
    border: none !important;
  }
  .rbc-time-view .rbc-row {
    min-height: 0;
  }

  .rbc-time-view .rbc-allday-cell {
    display: none;
  }
  .rbc-day-slot .rbc-event {
    border: none;
  }
  .rbc-event .rbc-selected {
    border: none;
  }
  .rbc-event:focus {
    outline: none;
  }
  .rbc-label {
    font-family: "Myriad-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 124.5%;
    color: #2b2b2b;
  }

  .rbc-event {
    height: unset !important;
    // top: 5% !important;
    // left: 0 !important;
  }
  .rbc-current-time-indicator {
    background-color: transparent !important;
  }
  .rbc-time-column {
    display: none;
  }

  .rbc-day-slot:nth-child(2) .rbc-time-column {
    display: block;
  }
  .rbc-day-bg:active {
    background-color: #e4bf44;
  }
  .rbc-button-link {
    position: relative !important;
    padding: 8px;
    border-radius: 50%;
  }
}

.time_picker {
  width: 180px !important;
  height: 48px !important;

  ::placeholder {
    color: #2b2b2b !important;
  }
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #e4bf44 !important;
}

.ant-btn-primary {
  background-color: #e4bf44 !important;
  color: #2b2b2b;
}
a {
  color: #e4bf44 !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active {
  background-color: #e4bf44 !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell:focus {
  background-color: #e4bf44 !important;
}
.ant-picker-time-panel-cell:active {
  background-color: #e4bf44 !important;
}
.ant-picker:hover,
:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-focused {
  border-color: #e4bf44 !important;
  box-shadow: none !important;
}
.ant-btn.ant-btn-sm {
  font-size: 13px;
  height: 24px;
  padding: 0px 14px;
  border-radius: 4px;
  color: #2b2b2b;
}
.ant-picker-dropdown .ant-picker-ranges {
  margin-top: 0;
}
.ant-picker-dropdown .ant-picker-time-panel-column {
  width: auto !important;

  &::-webkit-scrollbar {
    display: none;
  }
}
