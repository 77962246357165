@import "~react-big-calendar/lib/sass/styles";

.my-calendar-class {
  .rbc-header {
    border: none;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 124.5%;
    text-align: center;
    color: #45485b;
    .rbc-header + .rbc-header {
      border-left: none !important;
    }

    .rbc-row-bg {
      display: none !important;
    }
  }
  .rbc-date-cell {
    text-align: center;
  }
  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-today {
    background-color: transparent;
    .rbc-header-date {
      background: #e4bf44 !important;
    }
  }

  .rbc-current {
    .rbc-button-link {
      background-color: #e4bf44;
      padding: 8px;
      border-radius: 50%;
      position: relative !important;
      z-index: 1 !important;
    }
    /* Add this CSS to your stylesheet */
  }

  .rbc-row-segment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: #e4bf44;
    border-radius: 3px;
    padding: 4px 0px;

    width: 100%;
    font-size: 10px;
    color: #2b2b2b;
    font-weight: 600;
  }

  .rbc-day-slot .rbc-event-label {
    display: none;
  }
  .rbc-timeslot-group {
    width: 100%;
    min-height: 50px;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-time-header-content {
    border: none !important;
  }
  .rbc-time-view .rbc-row {
    min-height: 0;
  }
  .rbc-time-view .rbc-allday-cell {
    display: none;
  }
  // .rbc-day-slot .rbc-event {
  //   border: none;
  //   left: 0 !important;
  //   white-space: nowrap !important;
  //   max-width: 200px !important;
  //   width: 100% !important;

  //   @media (max-width: 1024px) {
  //     font-size: 7px;
  //     width: fit-content !important;
  //   }
  // }
  .rbc-event .rbc-selected {
    border: none;
  }
  .rbc-event:focus {
    outline: none;
  }
  .rbc-label {
    font-family: "Myriad-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 124.5%;
    color: #2b2b2b;
  }
  .rbc-event {
    height: unset !important;
    // top: 5% !important;
    // left: 0 !important;
  }
  .rbc-current-time-indicator {
    background-color: transparent !important;
  }
  .rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    color: #3174ad;
  }
  .rbc-button-link {
    position: relative !important;
    padding: 8px;
    border-radius: 50%;
  }
}
